.peace {
    padding: 15px;
    background-color: #E5DFCF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &~.peace {
        margin-top: 15px;
    }
}

.myPeace {
    background-color: lighten(#BABC94, 20%);
    border: 3px solid #BABC94;
}

.content {
    flex: 1;

    .info {
        padding-top: 15px;
        font-size: .8rem;
        font-style: italic;

        .author {
            padding-right: 10px;
        }
    }
}

.actions {
    padding-top: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    border-top: 1px solid #BABC94;

    div.toLove, div.report {
        color: lighten(black, 75%);
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                color: black;
            }
        }
    }

    div.loved {
        color: red;
        cursor:pointer;
    }
}

.myActions {
    padding-top: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    border-top: 1px solid #BABC94;

    div.delete {
        color: lighten(black, 75%);
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                color: black;
            }
        }
    }

    div.userloves {
        font-weight: bold;
        font-size: 1.5rem;
        cursor: default;
    }
}