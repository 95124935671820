@font-face{
  font-family: "main";
  src: url("./fonts/Raleway-Light.ttf") format("truetype");
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'main',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1E9D2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none !important;
  color: inherit;

  &:hover {
    color: darken(white, 5%);
  }
}