.profileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .logout {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: lighten(black, 50%);
        }
    }

    .totalLoves {
        font-size: 1.5rem;
        font-weight: bold;
    }
}