@import '../../mixins.scss';

.header {
    @include bg-primary;
}

.appcontent {
    @include app-layout;

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    flex-wrap: wrap;

    @media(max-width: 600px) {
        flex-direction: column;
    }
}