.gigantic {
    font-size: 3rem;
}

.large {
    font-size: 1.5rem;
}

.small {
    font-size: .8rem;
}

.center {
    text-align: center;
    width: 100%;
}