@import '../../mixins.scss';

.footer {
    @include bg-primary;
}

.applayout {
    @include app-layout;

    text-align: center;
}